<template>
  <section>
    <b-table
      striped

      :data="data"
      :loading="loading"
      :current-page="parseInt(page)"

      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"

      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort">

      <b-table-column field="vote_average" label="ID#" numeric v-slot="props">
        #{{ props.row.id.toString().padStart(5, "0") }}
      </b-table-column>

      <b-table-column field="rol" label="Tip" numeric v-slot="props">
        {{ props.row.tip }}
      </b-table-column>

      <!-- PF -->

      <b-table-column field="nume" label="Nume" v-slot="props" v-if="pf">
        {{ props.row.nume }}
      </b-table-column>

      <b-table-column field="prenume" label="Prenume" numeric v-slot="props" v-if="pf">
        {{ props.row.prenume }}
      </b-table-column>

      <b-table-column field="cnp" label="CNP" numeric v-slot="props" v-if="pf">
        {{ props.row.cnp }}
      </b-table-column>

      <b-table-column field="serie_ci" label="Serie CI" numeric v-slot="props" v-if="pf">
        {{ props.row.serie_ci }}
      </b-table-column>

      <b-table-column field="numar_ci" label="Numar CI" numeric v-slot="props" v-if="pf">
        {{ props.row.numar_ci }}
      </b-table-column>

      <!-- PJ -->

      <b-table-column field="denumire" label="Denumire" v-slot="props" v-if="pj">
        {{ props.row.denumire }}
      </b-table-column>

      <b-table-column field="cod_fiscal" label="Cod Fiscal" v-slot="props" v-if="pj">
        {{ props.row.cod_fiscal }}
      </b-table-column>

      <b-table-column field="cod_tva" label="Cod TVA" v-slot="props" v-if="pj">
        {{ props.row.cod_tva }}
      </b-table-column>

      <b-table-column label="Actiuni" v-slot="props">
        <b-dropdown aria-role="list" position="is-bottom-left" class="is-small ">
          <template #trigger="{ active }">
            <b-button
              label="Actiuni"
              size="is-small"
              :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>


          <b-dropdown-item aria-role="listitem" @click="edit(props.row.id)">Vezi/Modifica</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Registru agricol</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Taxe & Impozite</b-dropdown-item>
        </b-dropdown>
      </b-table-column>

    </b-table>
  </section>
</template>

<script>
import router from "@/router";
import * as _ from "lodash";
import { api } from "@/network";

export default {
  name: "TabelPlati",
  data() {
    return {
      data: [],
      total: 0,
      loading: false,
      sortField: "original_title",
      sortOrder: "asc",
      defaultSortOrder: "asc",
      page: this.$route.params.page || 1,
      perPage: 15,
    };
  },
  methods: {
    edit(id) {
      router.push(`/administrare/editeaza-persoana/${id}`);
    },
    loadAsyncData() {
      this.loading = true;

      api
        .post("plati", {
          perPage: this.perPage,
          page: this.page,
          searchString: this?.searchString,
        })
        .then(({ data }) => {
          // api.themoviedb.org manage max 1000 pages
          this.data = [];
          let currentTotal = data.total_results;
          if (data.total_results / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.total = currentTotal;
          data.results.forEach((item) => {
            item.release_date = item.release_date ? item.release_date.replace(/-/g, "/") : null;
            this.data.push(item);
          });
          this.loading = false;
        })
        .catch((error) => {
          this.data = [];
          this.total = 0;
          this.loading = false;
          throw error;
        });
    },
    /*
* Handle page-change event
*/
    onPageChange(page) {
      this.page = page;
      this.$router.push(`/administrare/persoane-${this.pf ? "fizice" : "juridice"}/${page}`);
      this.loadAsyncData();
    },
    /*
* Handle sort event
*/
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    /*
* Type style in relation to the value
*/
    type(value) {
      const number = parseFloat(value);
      if (number < 6) {
        return "is-danger";
      } else if (number >= 6 && number < 8) {
        return "is-warning";
      } else if (number >= 8) {
        return "is-success";
      }
    },
  },
  filters: {
    /**
     * Filter to truncate string, accepts a length parameter
     */
    truncate(value, length) {
      return value.length > length
        ? value.substr(0, length) + "..."
        : value;
    },
  },
  mounted() {
    this.loadAsyncData();
  },
  watch: {
    searchString() {
      console.log("--==searchString watcher==--");
      _.debounce(() => this.loadAsyncData(), 500);
    },
  },
};
</script>