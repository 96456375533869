<template>
  <div>
    <p class="title is-2">Plati</p>
    <p class="subtitle">On-Line</p>

    <section>
      <b-tabs v-model="activeTab" type="is-boxed">
        <b-tab-item label="Plati Noi" icon="alert-decagram">
          <TabelPlati />
        </b-tab-item>

        <b-tab-item label="Plati in Asteptare" icon="clock">
          <TabelPlati />
        </b-tab-item>

        <b-tab-item label="Plati Efectuate" icon="credit-card-outline">
          <TabelPlati />
        </b-tab-item>
      </b-tabs>
    </section>


  </div>
</template>

<script>
import TabelPlati from "@/components/app/plati/TabelPlati";

export default {
  name: "Plati",
  components: {
    TabelPlati
  },
  data() {
    return {
      activeTab: 0
    };
  }
};
</script>

<style scoped>

</style>